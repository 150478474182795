import * as React from "react"

import { locales } from "../../../config/i18n"

export const LocaleContext = React.createContext("de")

function LocaleProvider({ element, props }) {
  const { pageContext, location, path } = props
  const { locale } = pageContext

  const getLocalizedLocation = newLocale => {
    if (locale == newLocale) {
      return location.pathname
    }
    if (path === "/") {
      return locales[newLocale].default ? "/" : `/${newLocale}`
    }
    if (path.includes("/blog/")) {
      return locales[newLocale].default ? "/blog" : `/${newLocale}/blog`
    }
    if (locales[locale].default) {
      return `/${newLocale}${location.pathname}`
    }
    return location.pathname.slice(locale.length + 1)
  }

  return (
    <LocaleContext.Provider value={{ locale, getLocalizedLocation }}>
      {element}
    </LocaleContext.Provider>
  )
}

export default LocaleProvider
