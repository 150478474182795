const locales = {
  de: {
    default: true,
    path: `de`,
    locale: `de-CH`,
    siteLanguage: `de`,
    ogLanguage: `de_CH`,
  },
  en: {
    path: `en`,
    locale: `en-US`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
  },
}

function prefixLocalePath(language, path) {
  if (locales[language].default) {
    return path
  }
  return `/${locales[language].path}${path}`
}

module.exports = {
  locales,
  prefixLocalePath,
}
